import {CustomCssVarsFnParams, ShouldRenderVarsFnParams} from '../../../baseCustomCssVars';
import {CategoryListHeaderBehavior} from '../../../types/galleryTypes';

/* istanbul ignore file */
export const categoryTreeCustomCssVars = ({styleParams: styles}: CustomCssVarsFnParams) => {
  const shouldIndentCategoryList =
    styles.booleans.gallery_showCategoryListHeader &&
    styles.numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT;

  return {
    categoryListIndentationPx: shouldIndentCategoryList ? '28px' : '0px',
  };
};

export const categoryTreeShouldRenderVars = ({numbers}: ShouldRenderVarsFnParams) => {
  return {
    categoryListHeaderGoesBackToParent:
      numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT,
    categoryListHeaderDoesNothing: numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.DO_NOTHING,
  };
};
